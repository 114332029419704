@import '@angular/material/prebuilt-themes/indigo-pink.css';

/* You can add global styles to this file, and also import other style files */
html, body{
    padding: 0px;
    margin: 0px;

    overflow: hidden;




   /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, sans-serif; */
    font-family:'Arial Narrow bold', sans-serif;

}



.clickable{
    cursor: pointer;
}

.clickable:hover{
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset !important;
}
